document.addEventListener("turbolinks:load", function() {
  // Show Password
  function showHidePassword() {
    var password = event.target.previousElementSibling;
    if (password.type === "password") {
      password.type = "text";
      password.focus();
      event.target.innerHTML = "Hide";
    } else {
      password.type = "password";
      password.focus();
      event.target.innerHTML = "Show";
    }
  }

  document.addEventListener('click', function (event) {
    if (!event.target.classList.contains('js-show-password')) return;
    var showPassword = document.getElementsByClassName('js-show-password');
    for (var i = 0; i < showPassword.length; i++) {
      showHidePassword();
    }
  }, false);

  // Stop form being submitted without checkboxes being checked
  var message;
  var card;
  var cardNotice;

  function createCardNotice(container, message, status) {
    if(container.getElementsByClassName('alert').length > 0) {

      cardNotice = container.getElementsByClassName('alert');
      cardNotice[0].setAttribute("class", "alert " + status);
      cardNotice[0].innerHTML = message;
    } else {
      cardNotice = document.createElement('div');

      cardNotice.setAttribute("class", "alert " + status);

      cardNotice.innerHTML = message;

      container.insertBefore(cardNotice, card.firstChild);

    }
  }

  var form = document.getElementById('permit-summary');

  if(form !== null) {
    var submit = form.querySelector('input[type="submit"]');
    submit.addEventListener("click", function(event){
      validate(event)
    })
  }

  function validate(event) {
    var terms = document.getElementById('terms-check');
    var status = document.getElementById('status-check');
    card = event.target.parentNode;

    if (terms.checked && status.checked) {
        const elements = document.getElementsByClassName("alert");
        while (elements.length > 0) elements[0].remove();
    } else {
        event.preventDefault();
        message ='You need to agree to our Terms & Conditions and confirm you are a staff member, resident or visitor to continue.';
        status = 'error';
        createCardNotice(card, message, status);
    }
  }

  // Email Validation
  var email = document.getElementById('email-address');
  var confirmEmail = document.getElementById('confirm-email-address');
  if(confirmEmail != null) {
    var emailform = confirmEmail.form;
  }

  function emailError() {
    email.parentNode.classList.remove('success');
    confirmEmail.parentNode.classList.remove('success');
    email.parentNode.classList.add('error');
    confirmEmail.parentNode.classList.add('error');
  }

  function emailMatch() {
    email.parentNode.classList.remove('error');
    confirmEmail.parentNode.classList.remove('error');
    email.parentNode.classList.add('success');
    confirmEmail.parentNode.classList.add('success');
  }

  function emailClear() {
    email.parentNode.classList.remove('error');
    confirmEmail.parentNode.classList.remove('error');
    email.parentNode.classList.remove('success');
    confirmEmail.parentNode.classList.remove('success');
  }

  function validateEmailAddress() {

    var emailVal = email.value;
    var confirmEmailVal = confirmEmail.value;
    // Remove whitespace
    var emailValClean = emailVal.toLowerCase();
    var confirmemailValClean = confirmEmailVal.toLowerCase();

    if (emailValClean === '' && confirmemailValClean === '') {
      emailClear();
    } else if (emailValClean != '' && confirmemailValClean != '') {
      if (confirmemailValClean != emailValClean) {
        emailError();
        return false;
      } else {
        emailMatch();
        return true;
      }
    } else if (emailValClean === '' && confirmemailValClean != '') {
      emailError();
      return false;
    }
  }

  if (email != null && confirmEmail != null) {
    email.addEventListener("change", function (event) {
      validateEmailAddress();
    });
    confirmEmail.addEventListener("change", function (event) {
      validateEmailAddress();
    });
  }

  if (emailform != null) {
    var input = emailform.querySelectorAll('input[type="submit"]')
    input[0].addEventListener("click", function (event) {
      if( validateEmailAddress() === false ) {
        event.preventDefault();
        card = emailform.parentNode;
        message ='Email addresses do not match.';
        status = 'error';
        createCardNotice(card, message, status);
      } else {
        const elements = document.getElementsByClassName("alert");
        while (elements.length > 0) elements[0].remove();
      }
    });
  }
});

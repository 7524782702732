document.addEventListener("turbolinks:load", function() {
  var updateContainer = document.getElementById("update-permit-prices");
  var toggles = document.getElementsByClassName("tab");
  var priceInputs = document.querySelectorAll(".update-price-list li input");
  var carPrices = ['3.25', '1.40', '1.30', '1.20'];
  var bikePrices = ['2.25', '1.20', '1.00', '0.90'];

  if (toggles[0] != null) {
    toggles[0].addEventListener("click", function () {
      toggles[1].classList.remove('current');
      event.target.classList.add('current');
      for (i = 0; i < priceInputs.length; i++) {
        priceInputs[i].value = carPrices[i];
      }
    });
  }
  if (toggles[1] != null) {
    toggles[1].addEventListener("click", function () {
      toggles[0].classList.remove('current');
      event.target.classList.add('current');
      for (i = 0; i < priceInputs.length; i++) {
        priceInputs[i].value = bikePrices[i];
      }
    });
  }

});

document.addEventListener("turbolinks:load", function() {
  var tabs = document.querySelectorAll('.toggle-filter ul li');
  var current = document.getElementsByClassName('current');
  var statusType = document.querySelector('#generate-report #status-selector input#permit-status-field');

  for (var i = 0; i < tabs.length; i++) {
    tabs[i].addEventListener("click", function (e) {
      Array.prototype.forEach.call(tabs, function (node) {
        node.classList.remove("current");
      });
      if (document.querySelector('#generate-report #status-selector input#permit-status-field')) {
        populateStatus(e.target.innerHTML);
      }
      e.target.classList.add("current");
    });
  }

  var permitType = document.querySelectorAll('#generate-report #permit-type.toggle-filter input');
  var currentType = document.getElementsByClassName('current');
  var companySelector = document.getElementById('company-selector');
  var statusSelector = document.getElementById('status-selector');

  for (var j = 0; j < permitType.length; j++) {
    permitType[j].addEventListener("click", function (e) {
      if (e.target.value === "whitelist") {
        companySelector.classList.toggle("hide");
        statusSelector.classList.toggle("hide");
      } else if (e.target.value === "public") {
        statusSelector.classList.toggle("hide");
        companySelector.classList.toggle("hide");
      }
      e.target.classList.toggle("current")
    });
  }

  function populateStatus(currentStatus) {
    statusType.value = currentStatus;
  }
});

document.addEventListener("turbolinks:load", function() {
  //////////////////////////////////////////////////
  // Date range for Reports and Permit Filtering //
  /////////////////////////////////////////////////
  var startVal = document.getElementById("start-date");
  var startValResult;
  nullCheck(startVal, startValResult);

  var endVal = document.getElementById("end-date");
  var endValResult;
  nullCheck(endVal, endValResult);

  var fromVal = document.getElementById("from-date");
  var fromValResult;
  nullCheck(fromVal, fromValResult);

  var toVal = document.getElementById("to-date");
  var toValResult;
  nullCheck(toVal, toValResult);

  function nullCheck(id, result) {
    if (id != null && id.value == '') {
      let result = id.value
    }
  }

  var fromDate = flatpickr(".js-from-date", {
    altInput: true,
    altFormat: 'M j, Y',
    dateFormat: 'd-m-Y',
    maxDate: toValResult,
    onClose: function (selectedDates, dateStr, instance) {
      toDate.set('minDate', dateStr);
      document.getElementById('date-range-form').submit();
    }
  });

  let toDate = flatpickr(".js-to-date", {
    altInput: true,
    altFormat: 'M j, Y',
    dateFormat: 'd-m-Y',
    maxDate: fromValResult,
    onClose: function (selectedDates, dateStr, instance) {
      fromDate.set('maxDate', dateStr);
      document.getElementById('date-range-form').submit();
    }
  });

  /////////////////////////////////////////////////////
  ////////// Functions for Time generation ///////////
  ///////////////////////////////////////////////////

  function hasStartTime() {
    if (document.getElementById("start-time") !== null && document.getElementById("start-time") !== undefined) {
      return true;
    }
    return false;
  }

  var fromTime = hasStartTime() ? document.getElementById("start-time").dataset.validFromTime : null;

  function getStartTime() {
    return fromTime;
  }

  function hasEndTime() {
    if (document.getElementById("end-time") !== null && document.getElementById("end-time") !== undefined) {
      return true;
    }
    return false;
  }

  var toTime = hasEndTime() ? document.getElementById("end-time").dataset.validToTime : null;

  function getEndTime() {
    return toTime;
  }

  // Set Time Period
  let startTime = flatpickr(".js-start-time", {
    enableTime: true,
    noCalendar: true,
    dateFormat: 'H:i',
    time_24hr: true,
    defaultDate: getStartTime()
  });

  let endTime = flatpickr(".js-end-time", {
    enableTime: true,
    noCalendar: true,
    dateFormat: 'H:i',
    time_24hr: true,
    defaultDate: getEndTime()
  });


  /////////////////////////////////////////////////////////////////
  // Functions for Permits (Tenant and Guest) - Create and Edit //
  ///////////////////////////////////////////////////////////////

  function getTenantEndDate() {
      return tenantEndDate;
  }
  var tenantEndDate = isTenant() ? document.getElementById("end-date").dataset.validTo : 'today';

  function getTenantEndDatePermit() {
      return tenantEndDatePermit;
  }
  var tenantEndDatePermit = isTenant() ? document.getElementById("end-date").dataset.tenantEnd : null;

  function isTenant() {
    if (document.getElementById("tenant") !== null && document.getElementById("tenant") !== undefined) {
      if (document.getElementById("end-date").dataset.validTo !== null && document.getElementById("end-date").dataset.validTo !== undefined || document.getElementById("end-date").dataset.tenantEnd !== null && document.getElementById("end-date").dataset.tenantEnd !== undefined) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  // Check if the permit date is in the past or future
  function checkFutureDate() {
    getTenantEndDate();

    if(tenantEndDate !== null && tenantEndDate !== undefined) {

      var d = new Date();
      var parts = tenantEndDate.split('-');
      var tenantDate = new Date(parts[2] + '-' + parts[1] + '-' + parts[0]);
      if( tenantDate >= d ) {
        return 'today';
      } else {
        return tenantEndDate;
      }

    }
  }

  function checkDate() {
    getStartDate();

    if(minDate !== null && minDate !== undefined) {

      var d = new Date();
      var parts = minDate.split('-');
      var date = new Date(parts[2] + '-' + parts[1] + '-' + parts[0]);
      if( date >= d ) {
        return 'today';
      } else {
        return minDate;
      }

    }
  }

  function getStartDate() {
    return minDate;
  }

  var minDate = hasStartDate() ? document.getElementById("start-date").dataset.validFrom : 'today';

  function hasStartDate() {
    if (document.getElementById("start-date") !== null && document.getElementById("start-date") !== undefined) {
      if (document.getElementById("start-date").dataset.validFrom !== null && document.getElementById("start-date").dataset.validFrom !== undefined) {
        return true;
      } else {
        return false;
      }
      return false;
    }
  }

  function getEndDate() {
    return maxDate;
  }

  var maxDate = hasEndDate() ? document.getElementById("end-date").dataset.validTo : getTenantEndDatePermit();

  function hasEndDate() {
    if (document.getElementById("end-date") !== null && document.getElementById("end-date") !== undefined) {
      if (document.getElementById("end-date").dataset.validTo !== null && document.getElementById("end-date").dataset.validTo !== undefined) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  // Date picker for use on Tenant Permit Edit and
  // Guest Permit Creation/Edit
  let startDate = flatpickr(".js-start-date", {
    altInput: true,
    altFormat: 'M j, Y',
    dateFormat: 'd-m-Y',
    minDate: checkDate(),
    defaultDate: getStartDate(),
    maxDate: getEndDate(),
    onClose: function (selectedDates, dateStr, instance) {
      if (dateStr.length) {
        endDate.set('minDate', dateStr);
      }
    }
  });

  let endDate = flatpickr(".js-end-date", {
    altInput: true,
    altFormat: 'M j, Y',
    dateFormat: 'd-m-Y',
    minDate: checkDate(),
    maxDate: getTenantEndDatePermit(),
    defaultDate: getEndDate(),
    onClose: function (selectedDates, dateStr, instance) {
      if (dateStr.length) {
        startDate.set('maxDate', dateStr);
      }
    }
  });


  // Date picker for use on Tenant Permit Creation
  let startDateTenant = flatpickr(".js-start-date-tenant", {
    altInput: true,
    altFormat: 'M j, Y',
    dateFormat: 'd-m-Y',
    minDate: 'today',
    defaultDate: getStartDate(),
    maxDate: getEndDate(),
    onClose: function (selectedDates, dateStr, instance) {
      if (dateStr.length) {
        endDateTenant.set('minDate', dateStr);
      }
    }
  });

  let endDateTenant = flatpickr(".js-end-date-tenant", {
    altInput: true,
    altFormat: 'M j, Y',
    dateFormat: 'd-m-Y',
    minDate: 'today',
    maxDate: getTenantEndDatePermit(),
    onClose: function (selectedDates, dateStr, instance) {
      if (dateStr.length) {
        startDateTenant.set('maxDate', dateStr);
      }
    }
  });

  // Single datepicker for tenants
  let tenantDatePicker = flatpickr(".tenantDatePicker", {
    altInput: true,
    altFormat: 'M j, Y',
    dateFormat: 'd-m-Y',
    minDate: checkFutureDate(),
    defaultDate: getTenantEndDate()
  });

  // Standard Picker
  let datePicker = flatpickr(".datePicker", {
    altInput: true,
    altFormat: 'M j, Y',
    dateFormat: 'd-m-Y',
    minDate: 'today',
    defaultDate: 'today',
    maxDate: getEndDate()
  });

});

document.addEventListener("turbolinks:load", function() {


  // Trigger modal
  var modal = document.getElementById('modal');
  var trigger = document.getElementsByClassName("js-trigger-modal");
  var cancel = document.querySelector(".modal button.muted");

  document.addEventListener("click", function (e) {
    if (!e.target.classList.contains('js-trigger-modal')) return;
    e.preventDefault();
    var delete_url = e.target.href;
    openModal(delete_url);
  });

  document.addEventListener("click", function (e) {
    if (!e.target.classList.contains('overlay')) return;
    e.preventDefault();
    closeModal();
  });

  document.addEventListener("click", function (e) {
    if (!e.target.classList.contains('close-modal')) return;
    e.preventDefault();
    closeModal();
  });

  function openModal(delete_url) {
    modal.classList.add("visible");
    document.getElementById('delete-btn').setAttribute('href', delete_url);
  }

  function closeModal(e) {
    modal.classList.remove("visible");
  }


  // FAQ JS
  var jsaccordion = {
    init : function (target) {

      var headers = document.querySelectorAll("#" + target + " .faq-header");
      if (headers.length > 0) { for (var head of headers) {
        head.addEventListener("click", jsaccordion.select);
      }}
    },

    select : function () {

      var contents = this.nextElementSibling;
      var contentContainers = document.getElementsByClassName('faq-content');
      var arrows = document.getElementsByClassName('arrow');

      if (contents.classList.contains('open')) {
        contents.classList.remove("open");
        this.getElementsByClassName('arrow')[0].classList.remove('open');
      } else {
        if (contentContainers.length > 0) { for (var container of contentContainers) {
          container.classList.remove('open');
        }}
        if (arrows.length > 0) { for (var arrow of arrows) {
          arrow.classList.remove('open');
        }}
        this.getElementsByClassName('arrow')[0].classList.add('open');
        contents.classList.add("open");
      }
    }
  };


    jsaccordion.init("faq");


});

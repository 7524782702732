document.addEventListener("turbolinks:load", function() {

  var message;
  var card;
  var cardNotice;

  function createCardNotice(container, message, status) {
    if(container.getElementsByClassName('alert').length > 0) {
      cardNotice.innerHTML = message;
    } else {
      cardNotice = document.createElement('div');
      cardNotice.setAttribute("class", "alert " + status);
      cardNotice.innerHTML = message;
      container.insertBefore(cardNotice, card.firstChild);
    }
  }

  // Registration Validation
  var registration = document.getElementById('reg');
  var confirmRegistration = document.getElementById('confirm-reg');
  if(confirmRegistration != null) {
    var form = confirmRegistration.form;
  }

  function regError() {
    registration.parentNode.classList.remove('success');
    confirmRegistration.parentNode.classList.remove('success');
    registration.parentNode.classList.add('error');
    confirmRegistration.parentNode.classList.add('error');
  }

  function regMatch() {
    registration.parentNode.classList.remove('error');
    confirmRegistration.parentNode.classList.remove('error');
    registration.parentNode.classList.add('success');
    confirmRegistration.parentNode.classList.add('success');
  }

  function regClear() {
    registration.parentNode.classList.remove('error');
    confirmRegistration.parentNode.classList.remove('error');
    registration.parentNode.classList.remove('success');
    confirmRegistration.parentNode.classList.remove('success');
  }

  function validateRegistration() {

    var regVal = registration.value;
    var confirmregVal = confirmRegistration.value;
    // Remove whitespace
    var regValClean = regVal.replace(/\s/g, "").toLowerCase();
    var confirmregValClean = confirmregVal.replace(/\s/g, "").toLowerCase();

    if (regValClean === '' && confirmregValClean === '') {
      regClear();
    } else if (regValClean != '' && confirmregValClean != '') {
      if (confirmregValClean != regValClean) {
        regError();
        return false;
      } else {
        regMatch();
        return true;
      }
    } else if (regValClean === '' && confirmregValClean != '') {
      regError();
      return false;
    }
  }

  if (registration != null && confirmRegistration != null) {
    registration.addEventListener("change", function (event) {
      validateRegistration();
    });
    confirmRegistration.addEventListener("change", function (event) {
      validateRegistration();
    });
  }

  if (form != null) {
    var input = form.querySelectorAll('input[type="submit"]')
    input[0].addEventListener("click", function (event) {
      if( validateRegistration() === false ) {
        event.preventDefault();
        card = form.parentNode;
        message ='Registration numbers do not match.';
        status = 'error';
        createCardNotice(card, message, status);
      } else {
        //const elements = document.getElementsByClassName("alert");
        //while (elements.length > 0) elements[0].remove();
      }
    });
  }

});
